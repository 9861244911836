import { action, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbRecaptchaViewStore } from "mrb/components";
import { PasswordRecoveryForm } from "public/membership/forms";

class PasswordRecoveryViewStore extends MrbBaseViewStore {
    @observable success = false;

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;

        this.form = new PasswordRecoveryForm({
            onSuccess: () => {
                const data = this.form.values();
                return this.requestPasswordReset(data);
            },
            onInit: (form) => {
                form.$("email").focus();
            },
        });

        this.recaptchaViewStore = new MrbRecaptchaViewStore(this.rootStore, {
            siteKey: ApplicationSettings.recaptchaSiteKey,
        });
    }

    onInit() {
        const email = this.rootStore.routerStore.routerState.queryParams.email;
        if (email) {
            this.form.$("email").set(email);
        }
        return Promise.resolve();
    }

    @action requestPasswordReset = async (data) => {
        try {
            const recaptchaToken = await this.recaptchaViewStore.execute();
            data.recaptcha = recaptchaToken;
            await this.routeStore.requestPasswordReset(data);
            runInAction(() => {
                this.success = true;
            });
        } catch (err) {
            let message = "PASSWORD_RECOVERY.ERROR.GENERIC_MESSAGE";
            if (err && err.data) {
                if (err.data === "Unknown user.") {
                    message = "PASSWORD_RECOVERY.ERROR.UNKNOWN_USER_MESSAGE";
                }
            }
            this.form.invalidate(message);
        }
    };

    goBack = () => {
        this.rootStore.routerStore.goBack();
    };

    destroy() {
        super.destroy();
        this.form.destroy();
    }
}

export default PasswordRecoveryViewStore;
