import { MrbBaseRouteStore } from "mrb/core";

class LoginRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
    }

    login = async ({ username, password }) => {
        await this.rootStore.authenticationStore.login(username, password, [
            "sliding",
        ]);
        // Generaly we don't have to resolve user here.
        await this.rootStore.userStore.resolveUser();
        const redirect = this.rootStore.authenticationStore.getSignInRedirect();
        await this.rootStore.routerStore.goTo(redirect);
    };
}

export default LoginRouteStore;
