import React from "react";
import { RegisterTemplate } from "themes/public/membership/pages";
import { RegisterViewStore } from "public/membership/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new RegisterViewStore(rootStore, componentProps),
    "registerViewStore"
)
class Register extends React.Component {
    render() {
        return <RegisterTemplate {...this.props} />;
    }
}

export default Register;
