import React from "react";
import PropTypes from "prop-types";
import { MrbFieldInput, MrbEditFormLayout } from "mrb/components";
import { PasswordFieldInfo } from "common/components";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";

function PasswordResetTemplate({ passwordResetViewStore, t }) {
    const { form, success, goToLogin } = passwordResetViewStore;
    return (
        <section className="c-membership__wrapper">
            <div className="c-membership__form">
                <h1 className="c-membership__form__title">{t("PASSWORD_RESET.TITLE")}</h1>
                <MrbEditFormLayout form={form} t={t} submitDisabled={success} onClickCancel={goToLogin}>
                    <MrbFieldInput classNameExtend="c-input--outline" field={form.$("password")} t={t} />
                    <PasswordFieldInfo field={form.$("password")} />
                    <MrbFieldInput classNameExtend="c-input--outline" field={form.$("confirmPassword")} t={t} />

                    <PasswordResetStatus passwordResetViewStore={passwordResetViewStore} t={t} />
                </MrbEditFormLayout>
            </div>
        </section>
    );
}

PasswordResetTemplate.propTypes = {
    PasswordResetTemplate: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const PasswordResetStatus = observer(function PasswordResetStatus({ passwordResetViewStore, t }) {
    if (passwordResetViewStore.success) {
        return (
            <div className="c-validation--success">
                <p className="c-validation__label">
                    {t("PASSWORD_RESET.SUCCESS_MESSAGE", {
                        seconds: passwordResetViewStore.timer,
                    })}
                </p>
            </div>
        );
    }
    return null;
});

PasswordResetStatus.propTypes = {
    passwordResetViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(PasswordResetTemplate);
