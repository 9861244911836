import React from "react";
import PropTypes from "prop-types";
import { MrbFieldInput, MrbRecaptcha, MrbEditFormLayout } from "mrb/components";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";

function PasswordRecoveryTemplate({ passwordRecoveryViewStore, t }) {
    const { form, recaptchaViewStore, success, goBack } = passwordRecoveryViewStore;
    return (
        <section className="c-membership__wrapper">
            <div className="c-membership__form">
                <h1 className="c-membership__form__title">{t("PASSWORD_RECOVERY.TITLE")}</h1>
                <MrbEditFormLayout
                    form={form}
                    submitDisabled={success}
                    submitLabel="PASSWORD_RECOVERY.SUBMIT_BUTTON_LABEL"
                    t={t}
                    onClickCancel={goBack}
                >
                    <MrbFieldInput classNameExtend="c-input--outline" field={form.$("email")} t={t} />
                    <PasswordRecoveryStatus passwordRecoveryViewStore={passwordRecoveryViewStore} t={t} />
                </MrbEditFormLayout>
                <MrbRecaptcha store={recaptchaViewStore} t={t} />
            </div>
        </section>
    );
}

PasswordRecoveryTemplate.propTypes = {
    passwordRecoveryViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const PasswordRecoveryStatus = observer(function PasswordRecoveryStatus({ passwordRecoveryViewStore, t }) {
    if (passwordRecoveryViewStore.success) {
        return (
            <div className="c-validation--success">
                <p className="c-validation__label">{t("PASSWORD_RECOVERY.SUCCESS_MESSAGE")}</p>
            </div>
        );
    }
    return null;
});

PasswordRecoveryStatus.propTypes = {
    passwordRecoveryViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(PasswordRecoveryTemplate);
