import { moduleProviderFactory } from "mrb/core";
import {
    AccountActivation,
    Login,
    PasswordRecovery,
    PasswordReset,
    Register,
    ArtistRegister,
} from "public/membership/pages";
import {
    AccountActivationRouteStore,
    LoginRouteStore,
    PasswordRecoveryRouteStore,
    PasswordResetRouteStore,
    RegisterRouteStore,
    ArtistRegisterRouteStore,
} from "public/membership/stores";

(function () {
    moduleProviderFactory.module("public").register({
        name: "membership",
        routes: [
            {
                name: "master.public.membership",
                pattern: "",
                isPublic: true,
                children: [
                    {
                        name: "master.public.membership.activate-account",
                        pattern: "/account-activation",
                        isPublic: true,
                        component: AccountActivation,
                        store: AccountActivationRouteStore,
                    },
                    {
                        name: "master.public.membership.login",
                        pattern: "/sign-in",
                        isPublic: true,
                        component: Login,
                        store: LoginRouteStore,
                    },
                    {
                        name: "master.public.membership.signup",
                        pattern: "/sign-up",
                        isPublic: true,
                        component: Register,
                        store: RegisterRouteStore,
                    },
                    {
                        name: "master.public.membership.artist-signup",
                        pattern: "/artist-sign-up",
                        isPublic: true,
                        component: ArtistRegister,
                        store: ArtistRegisterRouteStore,
                    },
                    {
                        name: "master.public.membership.password-recovery",
                        pattern: "/password-recovery",
                        isPublic: true,
                        component: PasswordRecovery,
                        store: PasswordRecoveryRouteStore,
                    },
                    {
                        name: "master.public.membership.password-reset",
                        pattern: "/password-reset",
                        isPublic: true,
                        component: PasswordReset,
                        store: PasswordResetRouteStore,
                    },
                ],
            },
        ],
    });
})();
