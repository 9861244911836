import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { MrbFieldInput, MrbEditFormLayout, MrbButton } from "mrb/components";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";

function LoginTemplate({ loginViewStore, t }) {
    const { form, navigateToArtistSignUp } = loginViewStore;
    return (
        <React.Fragment>
            <div className="c-membership__wrapper">
                <MrbButton
                    className="c-btn c-btn--ghost c-btn--base u-mar--bottom--sml"
                    t={t}
                    label="For Artists and Podcasters"
                    onClick={navigateToArtistSignUp}
                />
                <div className="c-membership__cta">
                    <p>
                        Not a member?{" "}
                        <MrbRouterLink routeName="master.public.membership.signup">Sign up now</MrbRouterLink>.
                    </p>
                </div>
                <div className="c-membership__form c-membership__form--center">
                    <h1 className="c-membership__form__title">{t("LOGIN.TITLE")}</h1>
                    <MrbEditFormLayout
                        form={form}
                        submitLabel="LOGIN.SIGNIN_BUTTON"
                        t={t}
                        footer={<LoginFooter form={form} t={t} />}
                    >
                        <MrbFieldInput
                            t={t}
                            field={form.$("username")}
                            classNameExtend="c-input--outline"
                            autoComplete="email"
                        />
                        <MrbFieldInput
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("password")}
                            autoComplete="current-password"
                        />
                    </MrbEditFormLayout>
                </div>
            </div>
        </React.Fragment>
    );
}

LoginTemplate.propTypes = {
    loginViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const LoginFooter = observer(function LoginFooter({ form, t }) {
    const label = t("LOGIN.SIGNIN_BUTTON");
    return (
        <div className="c-form__footer">
            <MrbButton
                type="submit"
                aria-busy={form.submitting}
                label={label}
                className="c-btn c-btn--primary c-btn--med u-width--100"
                disabled={form.submitting || form.validating}
            />

            <div className="u-separator--primary u-mar--bottom--lrg u-mar--top--lrg"></div>

            <MrbRouterLink
                className="u-type--base u-type--center u-display--b"
                routeName="master.public.membership.password-recovery"
            >
                Forgot your password?
            </MrbRouterLink>
        </div>
    );
});

export default defaultTemplate(LoginTemplate);
