import { LocalizedForm } from "common/localization";

export default class PasswordRecoveryForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "email",
                    label: "PASSWORD_RECOVERY.FIELDS.EMAIL_LABEL",
                    placeholder: "PASSWORD_RECOVERY.FIELDS.EMAIL_PLACEHOLDER",
                    rules: "required|email|string",
                },
            ],
        };
    }
}
