import { action, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { PasswordResetForm } from "public/membership/forms";

class PasswordResetViewStore extends MrbBaseViewStore {
    @observable success = false;
    redirectTimer = null;
    @observable timer = 3;

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;

        this.form = new PasswordResetForm({
            onSuccess: async (form) => {
                const data = form.values();
                await this.resetPassword(data);
            },
            onInit: (form) => {
                form.$("password").focus();
            },
        });

        this.reaction(
            () => this.success,
            (success) => {
                if (success) {
                    this.redirectTimer = setInterval(() => {
                        runInAction(() => {
                            if (this.timer > 0) {
                                --this.timer;
                            }
                        });
                    }, 1000);
                }
            }
        );

        this.reaction(
            () => this.timer,
            (timer) => {
                if (timer === 0) {
                    this.rootStore.routerStore.goToLogin();
                }
            }
        );
    }

    @action resetPassword = async (data) => {
        try {
            await this.routeStore.resetPassword(data);
            runInAction(() => {
                this.success = true;
            });
        } catch (err) {
            if (err && err.data && err.data === "Unknown or expired password recovery token.") {
                this.form.invalidate("PASSWORD_RESET.ERROR.INVALID_TOKEN_MESSAGE");
            } else {
                this.form.invalidate("PASSWORD_RESET.ERROR.GENERIC_MESSAGE");
            }
        }
    };

    goToLogin = () => {
        this.rootStore.routerStore.goToLogin();
    };

    onDestroy() {
        if (this.redirectTimer) {
            clearInterval(this.redirectTimer);
        }
    }

    destroy() {
        super.destroy();
        this.form.destroy();
    }
}

export default PasswordResetViewStore;
