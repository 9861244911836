import { LocalizedForm } from "common/localization";
export default class LoginForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "username",
                    label: "LOGIN.USERNAME_LABEL",
                    placeholder: "LOGIN.USERNAME_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "password",
                    label: "LOGIN.PASSWORD_LABEL",
                    type: "password",
                    rules: "required|string",
                    placeholder: "LOGIN.PASSWORD_PLACEHOLDER",
                },
            ],
        };
    }
}
