import { MrbBaseRouteStore } from "mrb/core";

class PasswordResetRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
    }

    onEnter = (_, toState) => {
        if (this.rootStore.authenticationStore.isAuthenticated) {
        }
        this.passwordRecoveryToken = toState.queryParams.passwordRecoveryToken;
        if (!this.passwordRecoveryToken) {
        }
    };

    resetPassword = ({ password }) => {
        return this.rootStore.application.baasic.membershipModule.passwordRecovery.reset(
            {
                newPassword: password,
                passwordRecoveryToken: this.passwordRecoveryToken,
            }
        );
    };
}

export default PasswordResetRouteStore;
