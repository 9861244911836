import React from "react";
import { ArtistRegisterTemplate } from "themes/public/membership/pages";
import { ArtistRegisterViewStore } from "public/membership/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new ArtistRegisterViewStore(rootStore, componentProps),
    "artistRegisterViewStore"
)
class ArtistRegister extends React.Component {
    render() {
        return <ArtistRegisterTemplate {...this.props} />;
    }
}

export default ArtistRegister;
