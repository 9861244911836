import React from "react";
import { AccountActivationTemplate } from "themes/public/membership/pages";
import { AccountActivationViewStore } from "public/membership/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new AccountActivationViewStore(rootStore, componentProps),
    "accountActivationViewStore"
)
class AccountActivation extends React.Component {
    render() {
        return <AccountActivationTemplate {...this.props} />;
    }
}

export default AccountActivation;
