import { MrbBaseRouteStore } from "mrb/core";
import { split } from "lodash";
import moment from "moment";

class RegisterRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
    }

    register = (data) => {
        return this.rootStore.application.baasic.membershipModule.register.create(
            {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                confirmPassword: data.confirmPassword,
                userName: generateUserName(data.email),
                activationUrl: generateActivationUrl(),
                challengeResponse: data.recaptcha,
            }
        );
    };
}

function generateUserName(email) {
    const name = split(email, "@")[0].replace("+", "");
    return `${name}_${moment().unix()}`;
}

function generateActivationUrl() {
    return `${window.location.origin}/account-activation?activationToken={activationToken}`;
}

export default RegisterRouteStore;
