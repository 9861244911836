import { action, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseRouteStore, httpStatusCodes } from "mrb/core";

class AccountActivationRouteStore extends MrbBaseRouteStore {
    @observable accountActivationStatus = null;

    activationToken = null;

    accountActivationStatuses = {
        inProgress: "inProgress",
        success: "success",
        error: "error",
        tokenMissing: "tokenMissing",
        alreadyActivated: "alreadyActivated",
    };

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);
    }

    onEnter = async (_, toState) => {
        this.activationToken = toState.queryParams.activationToken;
        if (this.activationToken) {
            await this.activateAccount();
        } else {
            runInAction(() => {
                this.accountActivationStatus = this.accountActivationStatuses.tokenMissing;
            });
        }
    };

    @action activateAccount = async () => {
        try {
            this.accountActivationStatus = this.accountActivationStatuses.inProgress;
            await this.rootStore.application.baasic.membershipModule.register.activate(this.activationToken);
            runInAction(() => {
                this.accountActivationStatus = this.accountActivationStatuses.success;
            });
        } catch (err) {
            if (err && err.statusCode === httpStatusCodes.NotFound) {
                runInAction(() => {
                    this.accountActivationStatus = this.accountActivationStatuses.alreadyActivated;
                });
            }
        }
    };
}

export default AccountActivationRouteStore;
