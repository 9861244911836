import React from "react";
import { PasswordResetTemplate } from "themes/public/membership/pages";
import { PasswordResetViewStore } from "public/membership/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PasswordResetViewStore(rootStore, componentProps),
    "passwordResetViewStore"
)
class PasswordReset extends React.Component {
    render() {
        return <PasswordResetTemplate {...this.props} />;
    }
}

export default PasswordReset;
