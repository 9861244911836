import { LocalizedForm } from "common/localization";

export default class RegisterForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "email",
                    label: "REGISTER.FIELDS.EMAIL_LABEL",
                    placeholder: "REGISTER.FIELDS.EMAIL_PLACEHOLDER",
                    rules: "required|email|string",
                },
                {
                    name: "confirmEmail",
                    label: "REGISTER.FIELDS.CONFIRM_EMAIL_LABEL",
                    placeholder: "REGISTER.FIELDS.CONFIRM_EMAIL_PLACEHOLDER",
                    rules: "required|email|string|same:email",
                },
                {
                    name: "firstName",
                    label: "REGISTER.FIELDS.FIRST_NAME_LABEL",
                    placeholder: "REGISTER.FIELDS.FIRST_NAME_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "lastName",
                    label: "REGISTER.FIELDS.LAST_NAME_LABEL",
                    placeholder: "REGISTER.FIELDS.LAST_NAME_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "password",
                    label: "REGISTER.FIELDS.PASSWORD_LABEL",
                    type: "password",
                    placeholder: "REGISTER.FIELDS.PASSWORD_PLACEHOLDER",
                    rules: [
                        "required",
                        "string",
                        "min:8",
                        "regex:/([^a-zA-Z\\d])+([a-zA-Z\\d])+|([a-zA-Z\\d])+([^a-zA-Z\\d])+/",
                    ],
                },
                {
                    name: "confirmPassword",
                    label: "REGISTER.FIELDS.CONFIRM_PASSWORD_LABEL",
                    placeholder: "REGISTER.FIELDS.CONFIRM_PASSWORD_PLACEHOLDER",
                    rules: "required|string|same:password",
                    type: "password",
                },
                {
                    name: "privacyPolicy",
                    label: "REGISTER.FIELDS.PRIVACY_POLICY_LABEL",
                    rules: "accepted",
                    type: "checkbox",
                },
            ],
        };
    }
}
