import { MrbBaseViewStore } from "mrb/core";
import { computed, makeObservable, observable, runInAction } from "mobx";

class AccountActivationViewStore extends MrbBaseViewStore {
    @computed get activationStatus() {
        return this.routeStore.accountActivationStatus;
    }

    @computed get success() {
        return this.activationStatus === this.accountActivationStatuses.success;
    }

    get accountActivationStatuses() {
        return this.routeStore.accountActivationStatuses;
    }

    redirectTimer = null;
    @observable timer = 10;

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;

        const that = this;
        this.reaction(
            () => this.success,
            (success) => {
                if (success) {
                    this.redirectTimer = setInterval(() => {
                        runInAction(() => {
                            if (that.timer > 0) {
                                --that.timer;
                            }
                        });
                    }, 1000);
                }
            },
            {
                fireImmediately: true,
            }
        );

        this.reaction(
            () => this.timer,
            (timer) => {
                if (timer === 0) {
                    this.goToApp();
                }
            }
        );
    }

    goToApp = () => {
        this.rootStore.routerStore.goTo(this.rootStore.routerStore.initialState);
    };

    onDestroy() {
        if (this.redirectTimer) {
            clearInterval(this.redirectTimer);
        }
    }
}

export default AccountActivationViewStore;
