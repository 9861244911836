import { action, makeObservable, observable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbRecaptchaViewStore } from "mrb/components";
import { RegisterForm } from "public/membership/forms";
import MobxReactFormDevTools from "mobx-react-form-devtools";

class RegisterViewStore extends MrbBaseViewStore {
    @observable success = false;

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;

        this.form = new RegisterForm({
            onSuccess: () => {
                const data = this.form.values();
                return this.register(data);
            },
            onInit: (form) => {
                form.$("firstName").focus();
            },
        });

        this.recaptchaViewStore = new MrbRecaptchaViewStore(this.rootStore, {
            siteKey: ApplicationSettings.recaptchaSiteKey,
        });

        if (process.env.NODE_ENV === "development") {
            MobxReactFormDevTools.register({ register: this.form });
            MobxReactFormDevTools.select("register");
        }
    }

    @action register = async (data) => {
        try {
            const recaptchaToken = await this.recaptchaViewStore.execute();
            data.recaptcha = recaptchaToken;
            await this.routeStore.register(data);
            runInAction(() => {
                this.success = true;
            });
        } catch (err) {
            let message = "REGISTER.ERROR.GENERIC_MESSAGE";
            if (err && err.data) {
                if (err.data === "DuplicateUserName") {
                    message = "REGISTER.ERROR.DUPLICATE_USERNAME_MESSAGE";
                } else if (err.data === "DuplicateEmail") {
                    message = "REGISTER.ERROR.DUPLICATE_EMAIL_MESSAGE";
                } else if (err.data === "Failed challenge request.") {
                    message = "REGISTER.ERROR.RECAPTCHA_VERIFICATION_FAILED";
                }
            }
            this.form.invalidate(message);
        }
    };

    destroy() {
        super.destroy();
        this.form.destroy();
    }
}

export default RegisterViewStore;
