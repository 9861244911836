import { action, makeObservable, observable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { MrbRecaptchaViewStore } from "mrb/components";
import { ArtistRegisterForm } from "public/membership/forms";
import { find } from "lodash";
import { localizationService } from "common/localization";
import MobxReactFormDevTools from "mobx-react-form-devtools";

class ArtistRegisterViewStore extends MrbBaseViewStore {
    @observable success = false;
    @observable selectedProducerTypeSlug;

    producerTypes = [
        { value: 1, type: "artist", label: "As an Artist" },
        { value: 2, type: "podcaster", label: "As a Podcaster" },
    ];

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this.selectedProducerTypeSlug = this.producerTypes[0].type;

        this.form = new ArtistRegisterForm({
            onSuccess: () => {
                const data = this.form.values();
                return this.register(data);
            },
            onInit: (form) => {
                form.$("firstName").focus();
            },
        });

        this.recaptchaViewStore = new MrbRecaptchaViewStore(this.rootStore, {
            siteKey: ApplicationSettings.recaptchaSiteKey,
        });

        if (process.env.NODE_ENV === "development") {
            MobxReactFormDevTools.register({ register: this.form });
            MobxReactFormDevTools.select("register");
        }

        this.reaction(
            () => this.form.$("producerType").value,
            (value) => {
                this.setSelectedProducerTypeSlug(value);
                this.form.$("contentUsageAgreement").value = false;
                if (value === 1) {
                    this.form
                        .$("artistRegisterMessage")
                        .set(
                            "placeholder",
                            localizationService.t("REGISTER.FIELDS.ARTIST_REGISTER_MESSAGE_PLACEHOLDER.ARTIST")
                        );
                } else {
                    this.form
                        .$("artistRegisterMessage")
                        .set(
                            "placeholder",
                            localizationService.t("REGISTER.FIELDS.ARTIST_REGISTER_MESSAGE_PLACEHOLDER.PODCASTER")
                        );
                }
            }
        );
    }

    @action.bound
    async register(data) {
        try {
            const recaptchaToken = await this.recaptchaViewStore.execute();
            data.recaptcha = recaptchaToken;
            data.producerTypeSlug = this.selectedProducerTypeSlug;
            await this.routeStore.register(data);
            runInAction(() => {
                this.success = true;
            });
        } catch (err) {
            let message = "REGISTER.ERROR.GENERIC_MESSAGE";
            if (err && err.data) {
                if (err.data === "DuplicateUserName") {
                    message = "REGISTER.ERROR.DUPLICATE_USERNAME_MESSAGE";
                } else if (err.data === "DuplicateEmail") {
                    message = "REGISTER.ERROR.DUPLICATE_EMAIL_MESSAGE";
                } else if (err.data === "Failed challenge request.") {
                    message = "REGISTER.ERROR.RECAPTCHA_VERIFICATION_FAILED";
                }
            }
            this.form.invalidate(message);
        }
    }

    @action.bound
    setSelectedProducerTypeSlug(value) {
        var selectedProducerType = find(this.producerTypes, (producerType) => producerType.value === value);
        this.selectedProducerTypeSlug = selectedProducerType.type;
    }

    destroy() {
        super.destroy();
        this.form.destroy();
    }
}

export default ArtistRegisterViewStore;
