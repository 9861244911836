import { MrbBaseRouteStore } from "mrb/core";
import { UserAccountService } from "common/services";
import { split } from "lodash";
import moment from "moment";

class ArtistRegisterRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
        this.userAccountService = rootStore.createApplicationService(UserAccountService);
    }

    register(data) {
        return this.userAccountService.artistRegister({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            confirmPassword: data.confirmPassword,
            userName: generateUserName(data.email),
            activationUrl: generateActivationUrl(),
            challengeResponse: data.recaptcha,
            phoneNumber: data.phoneNumber,
            artistRegisterMessage: data.artistRegisterMessage,
            producerTypeSlug: data.producerTypeSlug,
        });
    }
}

function generateUserName(email) {
    const name = split(email, "@")[0].replace("+", "");
    return `${name}_${moment().unix()}`;
}

function generateActivationUrl() {
    return `${window.location.origin}/account-activation?activationToken={activationToken}`;
}

export default ArtistRegisterRouteStore;
