import React from "react";
import { PasswordRecoveryTemplate } from "themes/public/membership/pages";
import { PasswordRecoveryViewStore } from "public/membership/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PasswordRecoveryViewStore(rootStore, componentProps),
    "passwordRecoveryViewStore"
)
class PasswordRecovery extends React.Component {
    render() {
        return <PasswordRecoveryTemplate {...this.props} />;
    }
}

export default PasswordRecovery;
