import React from "react";
import { LoginTemplate } from "themes/public/membership/pages";
import { LoginViewStore } from "public/membership/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new LoginViewStore(rootStore, componentProps),
    "loginViewStore"
)
class Login extends React.Component {
    render() {
        return <LoginTemplate {...this.props} />;
    }
}

export default Login;
