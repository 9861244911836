import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import {
    MrbButton,
    MrbFieldInput,
    MrbFieldCheckbox,
    MrbRecaptcha,
    MrbEditFormLayout,
    MrbRecaptchaPrivacyPolicy,
} from "mrb/components";
import { PasswordFieldInfo } from "common/components";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";

function RegisterTemplate({ registerViewStore, t }) {
    const { form, success, recaptchaViewStore } = registerViewStore;
    return (
        <React.Fragment>
            <div className="c-membership__wrapper">
                <div className="c-membership__cta">
                    <p>
                        Already a member?{" "}
                        <MrbRouterLink routeName="master.public.membership.login">Sign in now</MrbRouterLink>.
                    </p>
                </div>
                <div className="c-membership__form">
                    <h1 className="c-membership__form__title">{t("REGISTER.TITLE")}</h1>
                    <MrbEditFormLayout
                        t={t}
                        form={form}
                        submitLabel="REGISTER.BUTTONS.SIGN_UP"
                        submitDisabled={success}
                        footer={<RegisterFooter form={form} t={t} submitDisabled={success} />}
                    >
                        <MrbFieldInput classNameExtend="c-input--outline" t={t} field={form.$("firstName")} />
                        <MrbFieldInput classNameExtend="c-input--outline" t={t} field={form.$("lastName")} />
                        <MrbFieldInput
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("email")}
                            autoComplete="email"
                        />
                        <MrbFieldInput
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("confirmEmail")}
                            autoComplete="email"
                        />
                        <MrbFieldInput
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("password")}
                            autoComplete="new-password"
                        />
                        <PasswordFieldInfo field={form.$("password")} />
                        <MrbFieldInput
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("confirmPassword")}
                            autoComplete="new-password"
                        />
                        <MrbFieldCheckbox
                            t={t}
                            field={form.$("privacyPolicy")}
                            labelRender={(props) => <PrivacyPolicyLabel {...props} />}
                        />
                        <RegistrationStatus registerViewStore={registerViewStore} t={t} />
                    </MrbEditFormLayout>
                    <MrbRecaptcha t={t} store={recaptchaViewStore} />
                </div>
            </div>
        </React.Fragment>
    );
}

RegisterTemplate.propTypes = {
    registerViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function PrivacyPolicyLabel({ id }) {
    return (
        <label htmlFor={id} className="u-mar--bottom--sml">
            By signing up, I accept the NakiRadio <a href="https://nakiradio.com/terms-of-use/">Terms and Conditions</a>{" "}
            and acknowledge the
            <a href="https://nakiradio.com/privacy-policy/"> Privacy Policy</a>.
        </label>
    );
}

const RegistrationStatus = observer(function RegistrationStatus({ registerViewStore, t }) {
    if (registerViewStore.success) {
        return (
            <div className="c-validation--success u-mar--top--sml">
                <p className="c-validation__label">{t("REGISTER.SUCCESS_MESSAGE")}</p>
            </div>
        );
    }
    return null;
});

const RegisterFooter = observer(function LoginFooter({ form, submitDisabled, t }) {
    const label = t("REGISTER.BUTTONS.SIGN_UP");
    return (
        <div className="c-form__footer">
            <MrbButton
                type="submit"
                aria-label={label}
                aria-busy={form.submitting || form.validating}
                label={label}
                className="c-btn c-btn--primary c-btn--med u-width--100"
                disabled={submitDisabled}
            />
            <div className="u-separator--primary u-mar--bottom--lrg u-mar--top--lrg"></div>

            <MrbRecaptchaPrivacyPolicy className="u-type--sml u-type--center" />
        </div>
    );
});

export default defaultTemplate(RegisterTemplate);
