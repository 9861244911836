import { action } from "mobx";
import { MrbBaseViewStore, httpStatusCodes } from "mrb/core";
import { LoginForm } from "public/membership/forms";
import MobxReactFormDevTools from "mobx-react-form-devtools";

class LoginViewStore extends MrbBaseViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;

        this.form = new LoginForm({
            onSuccess: async (form) => {
                const { username, password } = form.values();
                await this.login({
                    username,
                    password,
                });
            },
            onInit: (form) => {
                form.$("username").focus();
            },
        });

        if (process.env.NODE_ENV === "development") {
            MobxReactFormDevTools.register({ login: this.form });
            MobxReactFormDevTools.select("login");
        }
    }

    navigateToArtistSignUp = () => {
        this.rootStore.routerStore.goTo("master.public.membership.artist-signup");
    };

    @action login = async (data) => {
        try {
            await this.routeStore.login(data);
        } catch (err) {
            const { statusCode, data } = err;
            if (statusCode === httpStatusCodes.BadRequest) {
                if (data.error === "invalid_grant") {
                    this.form.invalidate("LOGIN.INVALID_CREDENTIALS");
                } else if (data.error === "invalid_grant_lock") {
                    this.form.invalidate("LOGIN.ERROR.USER_LOCKED");
                } else if (data.error === "invalid_grant_approve") {
                    this.form.invalidate("LOGIN.ERROR.USER_NOT_APPROVED");
                }
            }
        }
    };

    destroy() {
        super.destroy();
        this.form.destroy();
    }
}

export default LoginViewStore;
