import { MrbBaseRouteStore } from "mrb/core";

class PasswordRecoveryRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
    }

    requestPasswordReset = ({ email, recaptcha }) => {
        return this.rootStore.application.baasic.membershipModule.passwordRecovery.requestReset(
            {
                userName: email,
                challengeResponse: recaptcha,
                recoverUrl: generateRecoverUrl(),
            }
        );
    };
}

function generateRecoverUrl() {
    return `${window.location.origin}/password-reset?passwordRecoveryToken={passwordRecoveryToken}`;
}

export default PasswordRecoveryRouteStore;
