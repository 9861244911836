import { moduleProviderFactory } from "mrb/core";
import { PublicLayout } from "common/layouts";

(function () {
    moduleProviderFactory.module("public").register({
        name: "public",
        routes: [
            {
                name: "master.public",
                pattern: "",
                isPublic: true,
                component: PublicLayout,
                beforeEnter: (fromState, toState, routerStore) => {
                    const { authenticationStore } = routerStore.rootStore;
                    if (authenticationStore.isAuthenticated) {
                        return Promise.reject(routerStore.initialState);
                    }
                },
            },
        ],
    });
})();
