import React from "react";
import { PropTypes } from "prop-types";
import { defaultTemplate } from "common/hoc";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";

function AccountActivationTemplate({ accountActivationViewStore, t }) {
    const { goToApp, activationStatus, accountActivationStatuses } = accountActivationViewStore;
    return (
        <div className="c-membership__wrapper c-membership__wrapper--info">
            <h1 className="c-empty-state__title u-mar--bottom--med">{t("ACCOUNT_ACTIVATION.TITLE")}</h1>

            <p className="c-empty-state__desc--secondary">
                <ActivationStatus accountActivationViewStore={accountActivationViewStore} t={t} />
            </p>
            {activationStatus === accountActivationStatuses.success && (
                <React.Fragment>
                    <div className="u-mar--bottom--lrg c-card c-card--message c-card--message--password">
                        <p>{t("ACCOUNT_ACTIVATION.MOBILE_APP_MESSAGE")}</p>
                    </div>
                    <MrbButton
                        className="c-btn c-btn--primary c-btn--base c-btn--ghost"
                        onClick={goToApp}
                        t={t}
                        label="ACCOUNT_ACTIVATION.ACTIONS.GO_TO_PORTAL"
                    />
                </React.Fragment>
            )}
        </div>
    );
}

const ActivationStatus = observer(function ActivationStatus({ accountActivationViewStore, t }) {
    const { activationStatus, accountActivationStatuses, timer } = accountActivationViewStore;
    let message = null;
    switch (activationStatus) {
        case accountActivationStatuses.tokenMissing:
            message = t("ACCOUNT_ACTIVATION.MISSING_ACTIVATION_TOKEN_MESSAGE");
            break;
        case accountActivationStatuses.alreadyActivated:
            message = t("ACCOUNT_ACTIVATION.ALREADY_ACTIVATED_MESSAGE");
            break;
        case accountActivationStatuses.inProgress:
            message = t("ACCOUNT_ACTIVATION.IN_PROGRESS_MESSAGE");
            break;
        case accountActivationStatuses.success:
            message = t("ACCOUNT_ACTIVATION.SUCCESS_MESSAGE", {
                seconds: timer !== 1 ? `${timer} seconds` : `${timer} second`,
            });
            break;
        default:
            break;
    }

    return message;
});

AccountActivationTemplate.propTypes = {
    accountActivationViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(AccountActivationTemplate);
